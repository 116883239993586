<template>
    <div class="app-layout">
        <navbar :displayName="displayName" :isLoggedIn="isLoggedIn"/>
        <backend-updated-indicator></backend-updated-indicator>
        <div class="app-layout__content">
            <div class="app-layout__sidebar-wrapper" :class="{ minimized: isSidebarMinimized }">
                <div v-if="isFullScreenSidebar" class="d-flex justify--end">
                    <va-button
                        class="px-4 py-4"
                        icon="close"
                        flat
                        color="dark"
                        @click="onCloseSidebarButtonClick"
                    />
                </div>
                <sidebar
                    v-if="isLoggedIn"
                    :width="sidebarWidth"
                    :minimized="isSidebarMinimized"
                    :minimizedWidth="sidebarMinimizedWidth"
                />
            </div>
            <div class="app-layout__page">
                <div class="p-2 md:px-6 md:py-9">
                    <loading v-model:active="isAppLoading" :can-cancel="false" :is-full-page="true"/>
                    <router-view :key="this.$route.path" v-if="!isAppLoading"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import AuthHelper from '@/repositories/AuthHelper'
import BackendUpdatedIndicator from "@/components/BackendUpdatedIndicator";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'app-layout',
    components: {
        BackendUpdatedIndicator, Sidebar, Navbar, Loading
    },
    setup() {
        const store = useStore()
        const mobileBreakPointPX = 640
        const tabletBreakPointPX = 1024
        const sidebarWidth = ref('16rem')
        const sidebarMinimizedWidth = ref(undefined)
        const isAppLoading = ref(true)

        const displayName = computed(() => store.state.displayName)
        const isLoggedIn = computed(() => store.state.isLoggedIn)
        const isMobile = ref(false)
        const isTablet = ref(false)
        const isSidebarMinimized = computed(() => store.state.isSidebarMinimized)
        const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
        const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX
        const onResize = () => {
            store.commit('updateSidebarCollapsedState', checkIsTablet())
            isMobile.value = checkIsMobile()
            isTablet.value = checkIsTablet()
            sidebarMinimizedWidth.value = isMobile.value ? 0 : '3.75rem'
            sidebarWidth.value = isTablet.value ? '100%' : '16rem'
        }
        onMounted(() => {
            window.addEventListener('resize', onResize)
        })
        onBeforeUnmount(() => {
            window.removeEventListener('resize', onResize)
        })
        onResize()
        const isFullScreenSidebar = computed(() => isTablet.value && !isSidebarMinimized.value)
        const onCloseSidebarButtonClick = () => {
            store.commit('updateSidebarCollapsedState', true)
        }
        return {
            isSidebarMinimized,
            sidebarWidth,
            sidebarMinimizedWidth,
            isFullScreenSidebar,
            onCloseSidebarButtonClick,
            displayName,
            isLoggedIn,
            isAppLoading
        }
    },
    beforeCreate() {
        if (!this.$store.state.isLoggedIn) {
            AuthHelper.refresh().catch(() => {
                this.$router.push({name: 'LogIn'})
            }).finally(() => {
                this.isAppLoading = false
            })
        } else {
            this.isAppLoading = false
        }
    }
}
</script>

<style lang="scss">
@import 'scss/main.scss';

:root {
    --va-font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif !important;
}
body {
    line-height: 1.4rem !important;
}

#app {
    font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 1024px;
.app-layout {
    height: 100vh;
    display: flex;
    flex-direction: column;

    &__navbar {
        min-height: 4rem;
    }

    &__content {
        display: flex;
        height: calc(100vh - 4rem);
        flex: 1;
        @media screen and (max-width: $tabletBreakPointPX) {
            height: calc(100vh - 6.5rem);
        }

        .app-layout__sidebar-wrapper {
            position: relative;
            height: 100%;
            background: var(--va-white);
            @media screen and (max-width: $tabletBreakPointPX) {
                &:not(.minimized) {
                    width: 100%;
                    height: 100%;
                    position: fixed;
                    top: 0;
                    z-index: 999;
                }
                .va-sidebar:not(.va-sidebar--minimized) {
                    // Z-index fix for preventing overflow for close button
                    z-index: -1;

                    .va-sidebar__menu {
                        padding: 0;
                    }
                }
            }
        }
    }

    &__page {
        flex-grow: 2;
        overflow-y: scroll;
    }
}

h1.page-title {
    font-size: 28px;
    text-align: left;
}

.va-card {
    &__title {
        font-size: 16px !important;
    }
}

.va-modal__title {
    font-size: 1.2em !important;
}

.key-value-table {
    width: 100%;

    td:first-child {
        font-size: .725rem;
        font-weight: 700;
        letter-spacing: .6px;
        line-height: 1.6;
        text-transform: uppercase;
        width: 35%;
    }
}

.va-breadcrumb-item {
    font-family: var(--va-font-family);
    font-size: 28px;
    line-height: 1.2em;
    font-weight: 600;
}
.va-breadcrumbs__separator {
    font-size: 28px;
    line-height: 1.2em;
}

// Common style for help panels
.help {
    p {
        margin-top: 0.5em;
    }
    .va-table {
        width: 100%
    }
    h4 {
        font-weight: 600!important;
    }
    h4:not(:first-child) {
        margin-top: 1.5rem!important;
    }
}

// Fix dropdowns showing up behind modals
.va-dropdown__content {
    z-index: 1001;
}

.cancel-button {
    color: rgb(118, 124, 136) !important;
}

.jse-theme-dark {
  --jse-theme: dark;

  /* over all fonts, sizes, and colors */
  --jse-theme-color: #2f6dd0;
  --jse-theme-color-highlight: #467cd2;
  --jse-background-color: #1e1e1e;
  --jse-text-color: #d4d4d4;

  /* main, menu, modal */
  --jse-main-border: 1px solid #4f4f4f;
  --jse-menu-color: #fff;
  --jse-modal-background: #2f2f2f;
  --jse-modal-overlay-background: rgba(0, 0, 0, 0.5);
  --jse-modal-code-background: #2f2f2f;

  /* tooltip in text mode */
  --jse-tooltip-color: var(--jse-text-color);
  --jse-tooltip-background: #4b4b4b;
  --jse-tooltip-border: 1px solid #737373;
  --jse-tooltip-action-button-color: inherit;
  --jse-tooltip-action-button-background: #737373;

  /* panels: navigation bar, gutter, search box */
  --jse-panel-background: #333333;
  --jse-panel-background-border: 1px solid #464646;
  --jse-panel-color: var(--jse-text-color);
  --jse-panel-color-readonly: #737373;
  --jse-panel-border: 1px solid #3c3c3c;
  --jse-panel-button-color-highlight: #e5e5e5;
  --jse-panel-button-background-highlight: #464646;

  /* navigation-bar */
  --jse-navigation-bar-background: #656565;
  --jse-navigation-bar-background-highlight: #7e7e7e;
  --jse-navigation-bar-dropdown-color: var(--jse-text-color);

  /* context menu */
  --jse-context-menu-background: #4b4b4b;
  --jse-context-menu-background-highlight: #595959;
  --jse-context-menu-separator-color: #595959;
  --jse-context-menu-color: var(--jse-text-color);
  --jse-context-menu-pointer-background: #737373;
  --jse-context-menu-pointer-background-highlight: #818181;
  --jse-context-menu-pointer-color: var(--jse-context-menu-color);

  /* contents: json key and values */
  --jse-key-color: #9cdcfe;
  --jse-value-color: var(--jse-text-color);
  --jse-value-color-number: #b5cea8;
  --jse-value-color-boolean: #569cd6;
  --jse-value-color-null: #569cd6;
  --jse-value-color-string: #ce9178;
  --jse-value-color-url: #ce9178;
  --jse-delimiter-color: #949494;
  --jse-edit-outline: 2px solid var(--jse-text-color);

  /* contents: selected or hovered */
  --jse-selection-background-color: #464646;
  --jse-selection-background-inactive-color: #333333;
  --jse-hover-background-color: #343434;
  --jse-active-line-background-color: rgba(255, 255, 255, 0.06);
  --jse-search-match-background-color: #343434;

  /* contents: section of collapsed items in an array */
  --jse-collapsed-items-background-color: #333333;
  --jse-collapsed-items-selected-background-color: #565656;
  --jse-collapsed-items-link-color: #b2b2b2;
  --jse-collapsed-items-link-color-highlight: #ec8477;

  /* contents: highlighting of search results */
  --jse-search-match-color: #724c27;
  --jse-search-match-outline: 1px solid #966535;
  --jse-search-match-active-color: #9f6c39;
  --jse-search-match-active-outline: 1px solid #bb7f43;

  /* contents: inline tags inside the JSON document */
  --jse-tag-background: #444444;
  --jse-tag-color: #bdbdbd;

  /* contents: table */
  --jse-table-header-background: #333333;
  --jse-table-header-background-highlight: #424242;
  --jse-table-row-odd-background: rgba(255, 255, 255, 0.1);

  /* controls in modals: inputs, buttons, and `a` */
  --jse-input-background: #3d3d3d;
  --jse-input-border: var(--jse-main-border);
  --jse-button-background: #808080;
  --jse-button-background-highlight: #7a7a7a;
  --jse-button-color: #e0e0e0;
  --jse-button-secondary-background: #494949;
  --jse-button-secondary-background-highlight: #5d5d5d;
  --jse-button-secondary-background-disabled: #9d9d9d;
  --jse-button-secondary-color: var(--jse-text-color);
  --jse-a-color: #55abff;
  --jse-a-color-highlight: #4387c9;

  /* svelte-select */
  --background: #3d3d3d;
  --border: 1px solid #4f4f4f;
  --list-background: #3d3d3d;
  --item-hover-bg: #505050;
  --multi-item-bg: #5b5b5b;
  --input-color: #d4d4d4;
  --multi-clear-bg: #8a8a8a;
  --multi-item-clear-icon-color: #d4d4d4;
  --multi-item-outline: 1px solid #696969;
  --list-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);

  /* color picker */
  --jse-color-picker-background: #656565;
  --jse-color-picker-border-box-shadow: #8c8c8c 0 0 0 1px;
}

</style>
