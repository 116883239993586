<template>
    <VaPopover v-if="datetime" :message="parsedTimestamp.utc().format('lll [UTC]')">
        <span class="dt">{{ parsedTimestamp.format('lll') }}</span>
    </VaPopover>
    <span v-else>{{ placeholder }}</span>
</template>

<script>
import * as dayjs from 'dayjs'
const utc = require('dayjs/plugin/utc')
const localizedFormat = require('dayjs/plugin/localizedFormat')

export default {
    name: 'AppTimestamp',
    props: {
        datetime: String,
        placeholder: String
    },
    computed: {
        parsedTimestamp() {
            dayjs.extend(utc)
            dayjs.extend(localizedFormat)
            return dayjs(this.datetime)
        }
    }
}
</script>

<style scoped lang="scss">
.dt {
    border-bottom: 1px dotted #d7d7d7;
    text-decoration: none;
    cursor: help;
}
</style>
