<template>
    <va-button
        :preset="preset"
        :size="buttonSize"
        @click="showModal = true"
        :class="computedClass"
        :color="color"
        :icon="icon">
        {{ buttonLabel }}

        <!-- Modal is inside button to avoid grid layout issues -->
        <va-modal
            v-model="showModal"
            :title="title ? title : (buttonLabel + '?')"
            @ok="emitOk"
            @cancel="emitCancel"
            stateful
        >
            <p>{{message}}</p>
            <va-checkbox v-if="checkboxLabel" class="mt-4 mb-2" v-model="checkboxValue" :label="checkboxLabel" />
        </va-modal>
    </va-button>
</template>

<script>
export default {
    name: 'AppConfirm',
    props: {
        color: {
            type: String,
            default: 'danger'
        },
        buttonLabel: String,
        buttonSize: {
            type: String,
            default: 'medium'
        },
        checkboxLabel: String,
        message: String,
        class: String,
        preset: String,
        icon: String,
        title: String
    },
    emits: ['ok', 'cancel'],
    computed: {
        computedClass() {
            return this.class
        }
    },
    data() {
        return {
            showModal: false,
            checkboxValue: false
        }
    },
    methods: {
        emitOk() {
            this.showModal = false
            this.$emit("ok", {checkbox: this.checkboxValue})
        },
        emitCancel() {
            this.showModal = false
            this.$emit("cancel")
        }
    }
}
</script>

<style scoped lang="scss">
</style>
