<template>
    <va-navbar color="primary">
        <template #left>
            <va-navbar-item>
                <router-link v-if="isLoggedIn" :to="{name: 'Dashboard'}" class="hero">Wheelhouse</router-link>
                <span v-else>Wheelhouse</span>
            </va-navbar-item>
        </template>
        <template #right>
            <va-navbar-item>
                <div class="fakelink" @click="setColorMode(useColors.currentPresetName.value === 'light' ? 'dark' : 'light')">
                    <va-icon :name="useColors.currentPresetName.value === 'light' ? 'brightness_2' : 'wb_sunny'"/>
                </div>
            </va-navbar-item>

            <va-navbar-item v-if="isLoggedIn">
                {{ displayName }}
            </va-navbar-item>
            <va-navbar-item v-if="isLoggedIn">
                <div class="fakelink" @click="logout">
                    <va-icon name="logout"/>
                    Log Out
                </div>
            </va-navbar-item>
        </template>
    </va-navbar>
</template>

<script>
import AuthHelper from '@/repositories/AuthHelper';
import { useColors } from "vuestic-ui";

export default {
    name: "Navbar",
    props: {
        displayName: String,
        isLoggedIn: Boolean,
    },
    computed: {
        useColors
    },
    created() {
        this.setColorMode(localStorage.getItem('preferred_color_mode'))
    },
    methods: {
        logout() {
            AuthHelper.logout().then(() => this.$router.push({name: 'LogIn'}))
        },
        setColorMode(mode) {
            if (mode) {
                const { applyPreset } = useColors();
                applyPreset(mode)
                localStorage.setItem('preferred_color_mode', mode)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;

    &__left > * {
        margin-left: 1rem;
    }

    .va-navbar__item .hero,
    .va-navbar__item span {
        font-size: 150%;
    }

    &__center {
        @media screen and (max-width: 1200px) {
            .app-navbar__github-button {
                display: none;
            }
        }
        @media screen and (max-width: 950px) {
            .app-navbar__text {
                display: none;
            }
        }
    }

    @media screen and (max-width: 950px) {
        .left {
            width: 100%;
        }
        .app-navbar__actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
    }
}

.va-navbar__item > .fakelink {
    color: white;
    cursor: pointer;
}

.left {
    display: flex;
    align-items: center;

    & > * {
        margin-right: 1.5rem;
    }

    & > *:last-child {
        margin-right: 0;
    }
}

.x-flip {
    transform: scaleX(-100%);
}

.app-navbar__text > * {
    margin-right: 0.5rem;

    &:last-child {
        margin-right: 0;
    }
}

.hero:visited {
    color: white;
}
</style>